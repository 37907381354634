export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorPhone = phone => {
  /* eslint-disable no-useless-escape */
  const regExp = /\d+/
  /* eslint-enable no-useless-escape */
  const validPhone = regExp.test(phone)
  return validPhone
}

export const validatorNic = nic => {
  /* eslint-disable no-useless-escape */
  const regExp = /\d+/
  /* eslint-enable no-useless-escape */
  const validNic = regExp.test(nic)
  return validNic
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorDecimal = decimal => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^-?\d*(\.|\,)?\d*$/
  /* eslint-enable no-useless-escape */
  const validDecimal = cRegExp.test(decimal)
  return validDecimal
}

export const validatorSocialSecurityNumber = socialSecurityNumber => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^[12][0-9]{2}[0-1][0-9](2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}[0-9]{2}$/

  /* eslint-enable no-useless-escape */
  const validSocialSecurityNumber = cRegExp.test(socialSecurityNumber.replace(/ /g,''))
  return validSocialSecurityNumber
}
